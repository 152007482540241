import { useState } from "react";
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import Swal from "sweetalert2";
import useSWR from "swr";

import { useForm } from "../../hooks/useForm";
import colourStyles from '../../helpers/selectStyles';
import Axios from "axios";
import { fetchSinToken } from "../../helpers/fetch";
import toPascalCase from "../../helpers/pascalCase";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from '@mui/material';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

const fetcher = (url) => fetchSinToken(url);

export const CreateReferal = () => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [userSelected, setUserSelected] = useState(false);
  const [link, setLink] = useState(null);
  // const [name, setName] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  function formalizarNombre(nombre) {
    return nombre
      .trim() // Elimina los espacios en blanco al inicio y al final
      .split(" ") // Divide el string por espacios
      .map(palabra =>
        palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase()
      ) // Capitaliza la primera letra y pone el resto en minúscula
      .join(" "); // Vuelve a unir las palabras con espacios
  }

  const setRegisterManual = () => {
    setUserSelected(true);
  };

  const resetUser = () => {
    setUser(null);
    setUserSelected(false);
  };

  const [formRegisterValues, handleRegisterInputChange, reset] = useForm({
    rName: '',
  });

  const { rName } = formRegisterValues;

  const handleRegister = (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (inputValue === '') {
      setSubmitting(false);
      return Swal.fire(
        "Error",
        t('errors.fields_required'),
        "error"
      );
    }

    const startRegister = async (namee) => {
      const name = formalizarNombre(namee);
      const body = await fetchSinToken(
        "forms",
        { name },
        "POST"
      );
      if (body.ok) {
        setSubmitting(false);
        setLink("https://on-boarding-landing.onrender.com/" + body.referal.referal_code)
        // Swal.fire("Success", body.msg, "success");
      } else {
        setSubmitting(false);
        Swal.fire("Error", body.msg, "error");
      }
    }
    startRegister(inputValue);
  };

  const handleCopyToClipboard = () => {
    if (link) {
      navigator.clipboard.writeText(link).then(() => {
        Toastify({
          text: "Link copied to clipboard",
          duration: 3000
        }).showToast();
      }).catch(() => {
        alert('Failed to copy text to clipboard.');
      });
    }
  };


  const { data: usersApi } = useSWR("auth/users/notadmin", fetcher);
  const users = [];
  if (usersApi?.users) {
    const usersLen = usersApi?.users.length
    for (let i = 0; i < usersLen; i++) {
      // const user = {
      //   value: usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name,
      //   label: usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name
      // }
      users.push(usersApi?.users[i].name + ' ' + usersApi?.users[i].last_name);
    }
  }
  const [user, setUser] = useState(null);
  // const handleUser = (e) => {
  //   setUser(e);
  //   setUserSelected(true);
  //   reset({
  //     rName: e.user.name + " " + e.user.last_name,
  //   });
  // };
  const [inputValue, setInputValue] = useState('');
  const filterOptions = (options, { inputValue }) => {
    if (inputValue.length < 3) return []; // No mostrar sugerencias si hay menos de 3 caracteres
    return options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  return (
    <>
      {
        isMobile
          ?
          <div className="container block-scroll">
            {/* <h1 className="d-flex align-items-center justify-content-center mt-4" style={{ color: "#000" }}><img src={'https://res.cloudinary.com/drholseqp/image/upload/v1713815298/Azul_4x_xi9dte.png'} className="w-50" alt="Blue Energy Solar Logo" /></h1> */}
            <div className="row mt-5">
              <div className="col-md-11 col-11 login-form-1 m-3 pt-4 bg-light mt-5">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span className="text-center text-bold text-uppercase">Get your recruiting link here</span>
                </div>
                <form className="text-center" onSubmit={handleRegister}>
                  <label className="m-3">Type your name</label>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="w-100">
                      <Autocomplete
                        options={users}
                        filterOptions={filterOptions} // Filtrar opciones dinámicamente
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Type your name"
                            variant="outlined"
                            onChange={(e) => setInputValue(e.target.value)} // Actualiza el estado del input
                          />
                        )}
                        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                        onChange={(event, value) => setSelectedName(value)} // Captura el valor seleccionado
                        freeSolo // Permite al usuario escribir un valor que no esté en la lista
                      />
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center mt-2">
                    <input disabled={submitting} type="submit" className="btn btn-primary btn-bright" value="Get Link" />
                  </div>
                </form>
                {link &&
                  <div className="d-flex flex-column justify-content-center align-items-center p-2 py-4">
                    <span className="text-center">Your recruiting link is</span>
                    <a href={link} className="text-center text-bold text-no-decoration text-dark">{link}</a>
                    <div className="form-group d-flex align-items-center justify-content-center m-3">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={handleCopyToClipboard}
                      >
                        <i className="fa-solid fa-copy"></i>
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          :
          <div className="login-container" data-aos="fade-up" data-aos-duration="1000">
            {/* <h1 className="d-flex align-items-center justify-content-center py-4" style={{ color: "#000" }}><img src={'https://res.cloudinary.com/drholseqp/image/upload/v1713815298/Azul_4x_xi9dte.png'} className="w-30" alt="Blue Energy Solar Logo" /></h1> */}
            <div className="row mt-5">
              <div className="col-md-6 mt-5 col-6 me-5 login-form-3 bg-light">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span className="text-center text-bold text-1 text-uppercase">Get your recruiting link here</span>
                </div>
                <form className="text-center" onSubmit={handleRegister}>
                  <label className="mb-2">Type your name</label>
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="w-100">
                      <Autocomplete
                        options={users}
                        filterOptions={filterOptions} // Filtrar opciones dinámicamente
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Type your name"
                            variant="outlined"
                            onChange={(e) => setInputValue(e.target.value)} // Actualiza el estado del input
                          />
                        )}
                        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                        onChange={(event, value) => setSelectedName(value)} // Captura el valor seleccionado
                        freeSolo // Permite al usuario escribir un valor que no esté en la lista
                      />
                      {/* <Select
                        placeholder={t('select.placeholder')}
                        styles={colourStyles}
                        options={users}
                        value={name}
                        onChange={setName}
                        noOptionsMessage={() => 'No matches found'}
                        isClearable
                        // menuIsOpen={false}
                        components={{ DropdownIndicator: () => null }}
                        onInputChange={(inputValue, { action }) => {
                          if (action === 'input-change' && inputValue.length >= 2) {
                            console.log(inputValue.toLowerCase())
                            // Filtra opciones manualmente
                            const filteredOptions = users.filter((name) =>
                              name.label.toLowerCase().includes(inputValue.toLowerCase())
                            );
                            console.log(filteredOptions)
                            if (filteredOptions.length) {
                              setName({ value: inputValue, label: inputValue });
                            }
                          }
                        }}
                      /> */}
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <input disabled={submitting} type="submit" className="btn btn-primary btn-bright" value={'Get Link'} />
                  </div>
                </form>
                {link &&
                  <div className="d-flex flex-column justify-content-center align-items-center p-2">
                    <span className="text-center">Your recruiting link is</span>
                    <div className="form-group d-flex align-items-center justify-content-center gap-2">
                      <a href={link} className="text-center text-bold text-no-decoration text-dark">{link}</a>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={handleCopyToClipboard}
                      >
                        <i className="fa-solid fa-copy"></i>
                      </button>
                    </div>
                  </div>
                }
              </div>

            </div>
            {/* <div className="d-flex justify-content-center align-items-center p-5">
              <img style={{ height: '12vh', width: 'auto' }} height={75} width={75} src={'https://res.cloudinary.com/drholseqp/image/upload/v1734116079/be_sun2_kxqhgk.png'} alt="Blue Energy Solar Logo" />
            </div> */}
          </div>
      }
    </>
  );
};
