import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useSWR from "swr";
import { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css"
import { LoadingSpinnerWhite } from '../ui/LoadingSpinnerWhite';
import { format, isEqual, isValid } from 'date-fns';
import Select from 'react-select';
import colourStyles from '../../helpers/selectStyles';
import { BirthdayCard } from './BirthdayCard';

export const BirthdayScreen = ({ }) => {
  const { data: usersApi } = useSWR(`auth/users/birthday`);
  // const [birthdays, setBirthdays] = useState([]);
  // const [userss, setUserss] = useState([]);
  // const [userFinded, setUserFinded] = useState(null);
  const [userSelected, setUserSelected] = useState(false);

  // const [text1, setText1] = useState('We hope you feel sorrounded');
  // const [text2, setText2] = useState('by all the love and joy');
  // const [text3, setText3] = useState('you bring to others');

  const [text, setText] = useState(null);
  const texts = [
    { label: "We hope you feel sorrounded by all the love and joy you bring to others", value: "We hope you feel sorrounded by all the love and joy you bring to others" },
    { label: "May today be as extraordinary as the kindness and passion you share with everyone around you!", value: "May today be as extraordinary as the kindness and passion you share with everyone around you!" },
    { label: "Here's to a day filled with the same warmth and positivity that yu share with us all year round.", value: "Here's to a day filled with the same warmth and positivity that yu share with us all year round." },
    { label: "May your day be as bright as the happiness you bring into our team.", value: "May your day be as bright as the happiness you bring into our team." },
    { label: "Today, we celebrate you and the joy you spread every day Wishing you a fantastic birthday!", value: "Today, we celebrate you and the joy you spread every day Wishing you a fantastic birthday!" }
  ]

  const handleText = (e) => {
    setText(e);
  };


  // useEffect(() => {
  //   if (data?.birthdays.length > 0) {
  //     setBirthdays(data?.birthdays);
  //     setUserss(data?.users);
  //   }
  // }, [data]);

  const users = [];
  if (usersApi?.users) {
    const usersLen = usersApi?.users.length
    for (let i = 0; i < usersLen; i++) {
      const user = {
        value: usersApi?.users[i].name,
        label: usersApi?.users[i].name + " - " + format(new Date(usersApi?.users[i].date), "MM/dd/yyyy"),
        user: usersApi?.users[i]
      }
      users.push(user);
    }
  }
  const [user, setUser] = useState(null);
  const handleUser = (e) => {
    setUser(e);
    setUserSelected(true);
  };

  // const showUser = (a, date) => {
  //   const daySelected = date?.day;
  //   const monthSelected = date?.month?.number - 1;
  //   const yearSelected = date?.year;
  //   const selectedDate = new Date(yearSelected, monthSelected, daySelected)
  //   if (isValid(selectedDate)) {
  //     setUserFinded(null);

  //     const userFindedd = users.find((user) => {
  //       const userDate = new Date(user.date);
  //       userDate.setHours(0);
  //       return isEqual(userDate, selectedDate)
  //     })
  //     if (userFindedd) {
  //       setUserFinded(userFindedd);
  //     }
  //   }
  // }

  return (
    <div>
      {
        isMobile
          ?
          <div className='w-100 d-flex flex-column justify-content-center align-items-center text-center mt-3'>
            <div className="w-75 p-2">
              <label className='text-light text-bold'>Select an user</label>
              <Select placeholder="Select an user " styles={colourStyles} options={users} value={user} onChange={handleUser} />
            </div>
            <div className="w-75 px-2 pb-4">
              <label className='text-light text-bold'>Select a text</label>
              <Select placeholder="Select a text " styles={colourStyles} options={texts} value={text} onChange={handleText} />
            </div>
            {
              userSelected && user && text && <BirthdayCard user={user.user} text={text.value} />
            }
          </div>
          :
          <div className='w-100 text-center d-flex flex-column justify-content-center align-items-center'>
            <div className="w-50 p-2">
              <label className='text-light text-bold'>Select an user</label>
              <Select placeholder="Select an user " styles={colourStyles} options={users} value={user} onChange={handleUser} />
            </div>
            <div className="w-50 px-2 pb-4">
              <label className='text-light text-bold'>Select a text</label>
              <Select placeholder="Select a text " styles={colourStyles} options={texts} value={text} onChange={handleText} />
            </div>
            {
              userSelected && user && text && <BirthdayCard user={user.user} text={text.value} />
            }
          </div>
      }
    </div>
  );
};
