import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export const BirthdayCard = ({ user, text }) => {

  const splitText = (text) => {
    if (!text) return ["", "", ""]; // Si no hay texto, devolver cadenas vacías
    const words = text.split(" "); // Dividir el texto en palabras
    const totalWords = words.length;

    const part11 = words.slice(0, Math.floor(totalWords / 3)).join(" "); // Primera parte
    const part22 = words.slice(Math.floor(totalWords / 3), Math.floor((2 * totalWords) / 3)).join(" "); // Segunda parte
    const part33 = words.slice(Math.floor((2 * totalWords) / 3)).join(" ");
    return [part11, part22, part33];
  };

  const [part1, setPart1] = useState(null);
  const [part2, setPart2] = useState(null);
  const [part3, setPart3] = useState(null);

  useEffect(() => {
    const [part11, part22, part33] = splitText(text);
    setPart1(part11);
    setPart2(part22);
    setPart3(part33);
  }, [text]);



  // Dentro del componente BirthdayCard
  // const [part1, part2, part3] = splitText(text?.value);

  const emojis = [
    'https://res.cloudinary.com/drholseqp/image/upload/v1731697129/partying-face_1f973_catv4n.png',
    'https://res.cloudinary.com/drholseqp/image/upload/v1731697129/-balloon_1f388_rywdin.png',
    'https://res.cloudinary.com/drholseqp/image/upload/v1731697130/-birthday-cake_1f382_kcb436.png',
    'https://res.cloudinary.com/drholseqp/image/upload/v1731697130/-confetti-ball_1f38a_ukkvao.png',
    'https://res.cloudinary.com/drholseqp/image/upload/v1731697130/-bottle-with-popping-cork_1f37e_rljnzu.png',
  ];
  const [currentEmojiIndex, setCurrentEmojiIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const transitionDuration = 800; // Duración de la transición (en milisegundos)
  const intervalDuration = 1600;

  useEffect(() => {
    const interval = setInterval(() => {
      // Ocultar el emoji actual
      setIsVisible(false);

      // Cambiar al siguiente emoji después de que termine la transición
      setTimeout(() => {
        setCurrentEmojiIndex((prevIndex) => (prevIndex + 1) % emojis.length);
        setIsVisible(true);
      }, transitionDuration); // Espera lo mismo que la duración de la transición
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [emojis, transitionDuration, intervalDuration]);
  return (
    <div>
      {
        isMobile
          ?
          <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
            <div className='text-center d-flex flex-column justify-content-center align-items-center bg-birthday-img_mobile rounded-3 position-relative'>
              <img className={`emoji-birthday_mobile ${isVisible ? "" : "emoji-hidden_mobile"}`}
                style={{ objectFit: "cover", width: '12vh', height: '12vh' }}
                src={emojis[currentEmojiIndex]}
                alt="emoji"
              />
              <div className='animate__animated animate__pulse animate__infinite mt-5'>
                <img
                  className={`w-100`}
                  style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
                  src={'https://res.cloudinary.com/drholseqp/image/upload/v1732032163/HAPPY-BIRTHDAY2_jgsjhg.png'}
                  alt="happybirthday"
                />
              </div>
              <div className='mt-2'>
                <span className='birthday_name_mobile rounded-3 text-uppercase px-3 py-3 text-nowrap'>{user.name}</span>
              </div>

              <div className='mt-4 h-50 w-100 position-relative'>
                <div className="image-border-wrapper_mobile">
                  <div className="image-border_mobile rounded-circle">
                  </div>
                </div>
                <div className="image-wrapper-birthday_mobile">
                  <img className='rounded-circle' style={{ objectFit: 'cover' }} src={user.photo} width={200} height={200} />
                </div>
              </div>
              <div className='d-flex flex-column justify-content-start align-items-center pt-3'>
                <span className='birthday_text_1_mobile text-3'>{part1}</span>
                <span className='birthday_text_2_mobile text-3'>{part2}</span>
                <span className='birthday_text_2_mobile text-3'>{part3}</span>
              </div>
              <div className='my-4'>
                <img className='rounded-circle' style={{ objectFit: 'cover', width: '5vh', height: '5vh' }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1731696718/image_ofax4r.png'} width={35} height={35} />
              </div>
            </div>
          </div>
          :
          <div className='w-100'>
            <div className='text-center d-flex flex-column justify-content-start align-items-center bg-birthday-img rounded-3 position-relative'>
              <img className={`emoji-birthday ${isVisible ? "" : "emoji-hidden"}`}
                style={{ objectFit: "cover", width: '6.5vw', height: '6.5vw' }}
                src={emojis[currentEmojiIndex]}
                alt="emoji"
              />
              <div className='animate__animated animate__pulse animate__infinite'>
                <img
                  className={`w-100`}
                  style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
                  src={'https://res.cloudinary.com/drholseqp/image/upload/v1732032163/HAPPY-BIRTHDAY2_jgsjhg.png'}
                  alt="happybirthday"
                />
              </div>
              <div className='mt-2'>
                <span className='birthday_name rounded-3 text-uppercase px-3 py-3 text-nowrap'>{user.name}</span>
              </div>

              <div className='mt-4 h-100 w-100 position-relative '>
                <div className="image-border-wrapper">
                  <div className="image-border rounded-circle">
                  </div>
                </div>
                <div className="image-wrapper-birthday">
                  <img className='rounded-circle' style={{ objectFit: 'cover' }} src={user.photo} width={200} height={200} />
                </div>
              </div>
              <div className='d-flex flex-column justify-content-start align-items-center pt-3'>
                <span className='birthday_text_1 text-1-2'>{part1}</span>
                <span className='birthday_text_2 text-1-2'>{part2}</span>
                <span className='birthday_text_2 text-1-2'>{part3}</span>
              </div>
              <div className='mt-4'>
                <img className='rounded-circle' style={{ objectFit: 'cover' }} src={'https://res.cloudinary.com/drholseqp/image/upload/v1731696718/image_ofax4r.png'} width={35} height={35} />
              </div>
            </div>
          </div>
      }
    </div>
  );
};
