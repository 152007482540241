import { Outlet, useLocation } from "react-router-dom";

export const PublicLayout = () => {
    const path = useLocation().pathname;
    const location = path.split("/")[1];
    if (location === 'login') {
        document.body.style.overflow = "hidden"
    } else {
        document.body.style.overflow = "auto"
    }
    return (
        <div className={location === 'createreferal' ? 'bg-image-referal' : ''}>
            <Outlet />
        </div>
    );
}