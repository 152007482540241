import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { NotFoundScreen } from "../components/ui/NotFoundScreen";
import { Layout } from "./Layout";
import { startChecking } from "../actions/auth";
import { ProfileScreen } from "../components/user/ProfileScreen";
import { AdminScreen } from "../components/admin/AdminScreen";
import { EditUser } from "../components/admin/EditUser";
import { PublicLayout } from "./PublicLayout";
import { AddUser } from "../components/admin/AddUser";
import { AddUserData } from "../components/admin/AddUserData";
import { LoadingSpinner } from "../components/ui/LoadingSpinner";
import { isMobile } from "react-device-detect";
import { GiveCard } from "../components/admin/GiveCard";
import { StatsScreen } from "../components/stats/StatsScreen";
import { ShowWelcome } from "../components/auth/ShowWelcome";
import { EventsScreen } from "../components/events/EventsScreen";
import { AddEvent } from "../components/events/AddEvent";
import { RewardScreen } from "../components/rewards/RewardScreen";
import { AddReward } from "../components/rewards/AddReward";
import { ClaimedScreen } from "../components/rewards/ClaimedScreen";
import { AddUserDataCloser } from "../components/admin/AddUserDataCloser";
import { AssignCardCloser } from "../components/admin/AssignCardCloser";
import { StatsCloserScreen } from "../components/stats/closers/StatsCloserScreen";
import { AddPost } from "../components/posts/AddPost";
import { PostScreen } from "../components/posts/PostScreen";
import { AddManualUserData } from "../components/admin/AddManualUserData";
import { ExportCsv } from "../components/admin/ExportCsv";
import { RegisterScreen } from "../components/auth/RegisterScreen";
import { SpecialRewards } from "../components/rewards/SpecialRewards";
import { UsersDealsScreen } from "../components/admin/UsersDealsScreen";
import { AddDeal } from "../components/admin/AddDeal";
import { AddZoom } from "../components/admin/AddZoom";
import { TransferDeals } from "../components/admin/TransferDeals";
import { AdminUsersScreen } from "../components/admin/AdminUsersScreen";
import { AddUsersManual } from "../components/admin/AddUsersManual";
import { LoginScreen } from "../components/auth/LoginScreen";
import { AdminStatsData } from "../components/admin/AdminStatsData";
import { AdminStatsCanvasser } from "../components/admin/adminstats/AdminStatsCanvasser";
import { AdminStatsCloser } from "../components/admin/adminstats/AdminStatsCloser";
import { AdminStatsTotal } from "../components/admin/adminstats/AdminStatsTotal";
import { CalendarBirthdayScreen } from "../components/calendar/CalendarBirthdayScreen";
import { AdminStatsCloserOffices } from "../components/admin/adminstats/AdminStatsCloserOffices";
import { AdminStatsCloserSelfGen } from "../components/admin/adminstats/AdminStatsCloserSelfGen";
import { CallCenterScreen } from "../components/callcenter/CallCenterScreen";
import { AddCallCenter } from "../components/callcenter/AddCallCenter";
import { CallCenterAdminScreen } from "../components/callcenter/CallCenterAdminScreen";
import { AdminLeads } from "../components/admin/adminstats/AdminLeads";
import { AdminAppointments } from "../components/admin/adminstats/AdminAppointments";
import { AdminLeadsOffice } from "../components/admin/adminstats/AdminLeadsOffice";
import { AdminAppointmentsOffice } from "../components/admin/adminstats/AdminAppointmentsOffice";
import { EditCallCenter } from "../components/callcenter/EditCallCenter";
import { CallCenterAdminUsersScreen } from "../components/callcenter/CallCenterAdminUsersScreen";
import { EditUserCallCenter } from "../components/callcenter/EditUserCallcenter";
import { Home } from "../components/home/Home";
import { AddVisionBoard } from "../components/home/AddVisionBoard";
import { CardsDesktop } from "../components/cards_desktop/CardsDesktop";
import { CardsMobile } from "../components/cards_mobile/CardsMobile";
import { AddCard } from "../components/admin/AddCard";
import { AdminStatsTab } from "../components/admin/adminstats/screenshot/AdminStatsTab";
import { DeleteMasiveUsers } from "../components/admin/DeleteMasiveUsers";
import { UsersSitDownsScreen } from "../components/admin/sitdowns/UsersSitDownsScreen";
import { MondayScreen } from "../components/admin/monday/MondayScreen";
import { ConvertXlsx } from "../components/admin/ConvertXlsx";
import { OptionScreen } from "../components/admin/OptionScreen";
import { NotificationsSender } from "../components/admin/NotificationsSender";
import { RewardAdminScreen } from "../components/rewards/RewardAdminScreen";
import { ProjectsScreen } from "../components/admin/bonsaiproject/ProjectsScreen";
import { HotLeadsScreen } from "../components/admin/hotleads/HotLeadsScreen";
import { GetReportCalling } from "../components/admin/hotleads/GetReportCalling";
import { RingCentralScreen } from "../components/admin/ringcentral/RingCentralScreen";
import { RegisterBirthdayScreen } from "../components/auth/RegisterBirthdayScreen";
import { BirthdayScreen } from "../components/calendar/BirthdayScreen";
import { CreateReferal } from "../components/auth/CreateReferal";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { checking, uid, role, userAsoc, points, office, name } = useSelector((state) => state.auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdminDeals, setIsAdminDeals] = useState(false);
  const [isAdminUsers, setIsAdminUsers] = useState(false);
  const [isCloser, setIsCloser] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isCanvasser, setIsCanvasser] = useState(false);
  const [isCallCenter, setIsCallCenter] = useState(false);
  const [isNewCall, setIsNewCall] = useState(false);
  const [isAshten, setIsAshten] = useState(false);
  const [isRobertoA, setIsRobertoA] = useState(false);
  const [isLuisF, setIsLuisF] = useState(false);
  const [hotleadsAdmin, setHotleadsAdmin] = useState(false);

  useEffect(() => {
    dispatch(startChecking());
    if (role === 'Admin') {
      setIsCloser(false);
      setIsCanvasser(false);
      setIsAdmin(true);
      setIsAdminDeals(false);
      setIsAdminUsers(false);
      setIsCallCenter(false);
      setHotleadsAdmin(false);
      setIsNewCall(false);
    } else if (role === 'Closer') {
      setIsCloser(true);
      setIsCanvasser(false);
      setIsAdmin(false);
      setIsAdminDeals(false);
      setIsAdminUsers(false);
      setIsCallCenter(false);
      setHotleadsAdmin(false);
      setIsNewCall(false);
    } else if (role === 'Canvasser') {
      setIsCloser(false);
      setIsCanvasser(true);
      setIsAdmin(false);
      setIsAdminDeals(false);
      setIsAdminUsers(false);
      setIsCallCenter(false);
      setHotleadsAdmin(false);
      setIsNewCall(false);
    } else if (role === 'Admin Deals') {
      setIsAdminDeals(true);
      setIsCloser(false);
      setIsCanvasser(false);
      setIsAdmin(false);
      setIsAdminUsers(false);
      setIsCallCenter(false);
      setHotleadsAdmin(false);
      setIsNewCall(false);
    } else if (role === 'Admin Users') {
      setIsAdminDeals(false);
      setIsCloser(false);
      setIsCanvasser(false);
      setIsAdmin(false);
      setIsAdminUsers(true);
      setIsCallCenter(false);
      setHotleadsAdmin(false);
      setIsNewCall(false);
    } else if (role === 'Hotleads') {
      setIsAdminDeals(false);
      setIsCloser(false);
      setIsCanvasser(false);
      setIsAdmin(false);
      setIsAdminUsers(false);
      setIsCallCenter(false);
      setHotleadsAdmin(true);
      setIsNewCall(false);
    } else if (role === 'CallCenter New') {
      setIsCloser(false);
      setIsCanvasser(false);
      setIsAdmin(false);
      setIsAdminDeals(false);
      setIsAdminUsers(false);
      setIsCallCenter(false);
      setHotleadsAdmin(false);
      setIsNewCall(true);
    } else {
      setIsCloser(false);
      setIsCanvasser(false);
      setIsAdmin(false);
      setIsAdminDeals(false);
      setIsAdminUsers(false);
      setIsCallCenter(true);
      setHotleadsAdmin(false);
      setIsNewCall(false);
    }

    if (name === 'Ashten' && role === 'Closer') {
      setIsAshten(true);
    }

    if (name === 'Roberto Armando' && role === 'Closer') {
      setIsRobertoA(true);
    }

    if (name === 'Luis Felipe' && role === 'Closer') {
      setIsLuisF(true);
    }

    setTimeout(() => {
      setShowWelcome(false)
    }, 2500)

  }, [role]);

  if (checking) {
    return <LoadingSpinner />;
  }
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoute isAuthenticated={!!uid} />}>
          {
            showWelcome
              ?
              <Route index element={userAsoc && !isAdmin && <ShowWelcome user={userAsoc} id={uid} />} />
              :
              <Route path="/" element={<Layout isCallCenter={isCallCenter ? true : false} isNewCall={isNewCall ? true : false} />}>
                <Route index element={
                  isAdmin
                    ?
                    <AdminScreen />
                    :
                    isAdminDeals
                      ?
                      <UsersDealsScreen isAdmin={isAdmin} />
                      :
                      isAdminUsers
                        ?
                        <AdminUsersScreen isAdmin={isAdmin} />
                        :
                        (isCallCenter || isNewCall)
                          ?
                          <CallCenterScreen id={uid} isNewCall={isNewCall} />
                          :
                          hotleadsAdmin
                            ?
                            <HotLeadsScreen />
                            :
                            <>
                              {
                                uid && userAsoc &&
                                <Home id={uid} user={userAsoc} points={points} isAdmin={isAdmin} />
                              }
                            </>
                } />
                <Route path="/rankings" element={(isAdmin || isAdminUsers || isAdminDeals) && <Home id={uid} user={userAsoc} points={points} isAdmin={isAdmin} />} />
                <Route path="/addusers" element={isAdmin && <AddUser />} />
                <Route path="/addusersmanual" element={(isAdmin || isAdminUsers) && <AddUsersManual />} />
                <Route path="/edituser" element={(isAdmin || isAdminUsers) && <EditUser />} />
                <Route path="/userlist" element={(isAdmin || isAdminUsers) && <AdminUsersScreen isAdmin={isAdmin} />} />
                <Route path="/deletemasiveusers" element={(isAdmin || isAdminUsers) && <DeleteMasiveUsers isAdmin={isAdmin} />} />
                <Route path="/addusersdata" element={isAdmin && <AddUserData />} />
                <Route path="/addusersdatacloser" element={isAdmin && <AddUserDataCloser />} />
                <Route path="/addmanualusersdata" element={isAdmin && <AddManualUserData />} />

                <Route path="/projects" element={isAdmin && <ProjectsScreen />} />

                <Route path="/ringcentral" element={isAdmin && <RingCentralScreen />} />

                <Route path="/hotleads" element={isAdmin && <HotLeadsScreen />} />
                <Route path="/reportcalling" element={isAdmin && <GetReportCalling />} />

                <Route path="/userdeals" element={(isAdmin || isAdminDeals) && <UsersDealsScreen />} />
                <Route path="/adddeals" element={(isAdmin || isAdminDeals) && <AddDeal />} />

                <Route path="/usersitdowns" element={isAdmin && <UsersSitDownsScreen />} />

                <Route path="/monday" element={isAdmin && <MondayScreen />} />

                <Route path="/exportcsv" element={isAdmin && <ExportCsv />} />
                <Route path="/userscallcenter" element={isAdmin && <CallCenterAdminUsersScreen />} />
                <Route path="/editusercallcenter" element={isAdmin && <EditUserCallCenter />} />
                <Route path="/addcard" element={isAdmin && <AddCard />} />
                {/* <Route path="/calendar/birthday" element={<CalendarBirthdayScreen />} /> */}
                <Route path="/calendar/birthday" element={<BirthdayScreen />} />

                <Route path="/addvisionboard" element={<AddVisionBoard id={uid} />} />

                <Route path="/addhours" element={(isAdmin || isCallCenter || isNewCall) && <AddCallCenter id={uid} isAdmin={isAdmin} isNewCall={isNewCall} />} />
                <Route path="/edithours/:hours" element={(isAdmin || isCallCenter || isNewCall) && <EditCallCenter isAdmin={isAdmin} isNewCall={isNewCall} />} />
                <Route path="/callcenter" element={isAdmin && <CallCenterAdminScreen />} />

                <Route path="/statsdata" element={(isAdmin || isAdminDeals) && <AdminStatsData />} />
                <Route path="/adminstats" element={(isAdmin || isAdminDeals || isAshten || isRobertoA || isLuisF) && <AdminStatsTab id={uid} office={(isAdmin || isAdminDeals) ? 'All' : office} isAdmin={isAdmin ? true : false} isAutomatic={isRobertoA ? true : false} />} />

                <Route path="/statsleads" element={(isAdmin || isAdminDeals) && <AdminLeads />} />
                <Route path="/statsappointments" element={(isAdmin || isAdminDeals) && <AdminAppointments />} />
                <Route path="/statsleadsoffice" element={(isAdmin || isAdminDeals) && <AdminLeadsOffice />} />
                <Route path="/statsappointmentsoffice" element={(isAdmin || isAdminDeals) && <AdminAppointmentsOffice />} />

                <Route path="/statstotal" element={(isAdmin || isAdminDeals) && <AdminStatsTotal cancels={false} />} />
                <Route path="/statscloser" element={(isAdmin || isAdminDeals) && <AdminStatsCloser cancels={false} />} />
                <Route path="/statscanvasser" element={(isAdmin || isAdminDeals) && <AdminStatsCanvasser cancels={false} />} />
                <Route path="/statsselfgen" element={(isAdmin || isAdminDeals) && <AdminStatsCloserSelfGen cancels={false} />} />
                <Route path="/statsoffices" element={(isAdmin || isAdminDeals) && <AdminStatsCloserOffices cancels={false} />} />

                <Route path="/statstotalno" element={(isAdmin || isAdminDeals) && <AdminStatsTotal cancels={true} />} />
                <Route path="/statscloserno" element={(isAdmin || isAdminDeals) && <AdminStatsCloser cancels={true} />} />
                <Route path="/statscanvasserno" element={(isAdmin || isAdminDeals) && <AdminStatsCanvasser cancels={true} />} />
                <Route path="/statsselfgenno" element={(isAdmin || isAdminDeals) && <AdminStatsCloserSelfGen cancels={true} />} />
                <Route path="/statsofficesno" element={(isAdmin || isAdminDeals) && <AdminStatsCloserOffices cancels={true} />} />

                <Route path="/adminrewards" element={isAdmin && <RewardAdminScreen />} />

                <Route path="/givecard" element={isAdmin && <GiveCard />} />
                <Route path="/edituser" element={isAdmin && <EditUser />} />
                <Route path="/addevent" element={isAdmin && <AddEvent />} />
                <Route path="/addreward" element={isAdmin && <AddReward />} />
                <Route path="/addpost" element={isAdmin && <AddPost />} />
                <Route path="/addzoom" element={isAdmin && <AddZoom />} />
                <Route path="/notificationssender" element={isAdmin && <NotificationsSender />} />
                <Route path="/optionscreen" element={isRobertoA && <OptionScreen />} />
                <Route path="/transferdeals" element={isAdmin && <TransferDeals />} />
                <Route path="/assigncard" element={isAdmin && <AssignCardCloser />} />
                <Route path="/events" element={<EventsScreen isCloser={isCloser} id={uid} />} />
                <Route path="/rewards" element={<RewardScreen id={uid} points={points} />} />
                <Route path="/claimed" element={<ClaimedScreen id={uid} />} />
                <Route path="/special" element={<SpecialRewards id={uid} />} />
                <Route path="/announcements" element={<PostScreen id={uid} />} />
                <Route path="/convertexcel" element={<ConvertXlsx />} />
                <Route path="/cards" element={isMobile ? <CardsMobile id={uid} points={points} /> : <CardsDesktop id={uid} points={points} />} />
                <Route path="/stats" element={isCloser ? <StatsCloserScreen user={userAsoc} id={uid} /> : <StatsScreen user={userAsoc} id={uid} />} />
                <Route path="/profile" element={<ProfileScreen user={userAsoc} id={uid} />} />
                <Route path="*" element={<NotFoundScreen />} />
              </Route>
          }

        </Route>
        <Route element={<PublicRoute isAuthenticated={!!uid} />}>
          <Route path="/" element={<PublicLayout />}>
            <Route index path="/login" element={<LoginScreen />} />
            <Route path="/register" element={<RegisterScreen />} />
            <Route path="/registerbirthday" element={<RegisterBirthdayScreen />} />
            <Route path="/createreferal" element={<CreateReferal />} />
          </Route>
        </Route>
      </Routes>
    </Router>

  );
};
